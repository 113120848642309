import api from '../axios';
import config from '@/constants/config';
const resource = '/api/mp/settings/';

export default {


  getApplicationListVacantShifts: (filterQuery) => api.get(`api/locum/shifts/vacant?${filterQuery}`),
  applyVacantshifts: (id) => api.get(`api/locum/shifts/apply/${id}`),
  showVacuntShift: () => api.put(`api/locum/shifts/vacant/shift`),
  // calender data

  myShift: (start, end) => api.get(`api/locum/shifts?start=${start}&end=${end}`),
  vacantShiftForCalender: (start, end) => api.get(`api/locum/shifts/vacantShiftForCalender?start=${start}&end=${end}`),
  appliedShiftForCalender: (start, end) => api.get(`api/locum/shifts/appliedShiftForCalender?start=${start}&end=${end}`),

  // connection request
  sendConnectionRequest:(payload) => api.post(`api/locum/locations/requests`, payload),


};
