<template>
  <div class="invoice-wrapper">
    <b-row>
      <b-col cols="6">
        <b-button class="shadow-sm font-weight-bold p-1 align-self-baseline float-left bg-white"
                  variant="white"
                  @click="$router.back()">
          <feather-icon class="txt-green" icon="ChevronLeftIcon" size="20"/>
        </b-button>
        <span class="m-1 " style="font-size: 25px"><strong>Vacant Shifts </strong>
          <span class="translate-middle badge rounded-pill bg-danger"
                style="font-size: 0.6em;">{{ this.noDataTable }}</span>
          </span>
      </b-col>
      <b-col cols="6">
        <div class="d-flex justify-content-end float-right">

          <!--          <b-button-->
          <!--              class="shadow-sm font-weight-bold p-1 align-self-baseline float-left bg-white"-->
          <!--              style="margin-right: 4px;"-->
          <!--              variant="white">-->
          <!--            <feather-icon class="txt-green" icon="DownloadIcon" size="20"/>-->
          <!--          </b-button>-->

          <!--          <b-button-->
          <!--              class="shadow-sm font-weight-bold p-1 align-self-baseline float-left bg-white"-->
          <!--              style="margin-right: 4px;"-->
          <!--              variant="white">-->
          <!--            <feather-icon class="txt-green" icon="PrinterIcon" size="20"/>-->
          <!--          </b-button>-->

          <!--          <b-button-->
          <!--              class="shadow-sm font-weight-bold p-1 align-self-baseline float-left bg-white"-->
          <!--              style="margin-right: 4px;"-->
          <!--              variant="white">-->
          <!--            <feather-icon class="txt-green" icon="TrashIcon" size="20"/>-->
          <!--          </b-button>-->
          <b-form-checkbox
              v-model="userApplyShowVacantShiftCalendar"
              class="custom-control-primary"
              name="check-button"
              switch
              v-on:input="changeVacantShiftShowState()"
          >
              <span class="">
      <!--          <feather-icon icon="CheckIcon" />-->
                <!--        </span>-->
                <!--                <span class="switch-icon-right">-->
                <!--          <feather-icon icon="XIcon" />-->
                <!-- {{this.toggle}} -->
              </span>
            <div>Show vacant shifts on my calendar</div>
            <!-- <b-button variant="primary" @click="getShowVacuntShift()">test</b-button> -->
          </b-form-checkbox>

        </div>
      </b-col>
    </b-row>


    <b-alert
        class="mt-1"
        show
        variant="primary"
    >
      <div class="alert-body">
        <feather-icon
            class="mr-25"
            icon="AlertCircleIcon"
        />
        <span class="ml-25 text-dark">All listed shifts are applicable to your role only: {{ currentUser.locum.job_role.name }}</span>
        <!--                <span class="ml-25">All listed shifts are applicable to your role only: Occupational Therapist.</span>-->
      </div>
    </b-alert>
    <b-card>
      <b-row>
        <b-col cols="2">
          <b-form-group>
            <v-select
                v-model="location"
                :options="locationFilterOptions"
                :reduce="locationFilterOptions.id"
                class="mb-2"
                label="name"
                multiple
                placeholder="All Locations"
            />
          </b-form-group>
          <!-- {{filters['location']}} -->
        </b-col>
        <b-col cols="2">
          <b-form-group>
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="CalendarIcon"/>
              </b-input-group-prepend>
              <flat-pickr
                  v-model="filters['start']"
                  :config="{ enableTime: false, noCalendar: false, dateFormat: 'Y-m-d',}"
                  class="form-control mr-1 "
                  placeholder="Select date"
              />
            </b-input-group>
            <!-- {{filters['start']}} -->
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group>
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                £
              </b-input-group-prepend>
              <b-form-input
                  v-model="filters['min']"
                  name="login-email"
                  placeholder="Min rate/h"
                  type="number"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group>
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                £
              </b-input-group-prepend>
              <b-form-input
                  v-model="filters['max']"
                  name="login-email"
                  placeholder="Max rate/h"
                  type="number"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-overlay
              :show="tableLoading"
              rounded="sm"
          >

            <b-table
                ref="table"
                :current-page="currentPage"
                :fields="fields"
                :filter="filter"
                :filter-included-fields="filterOn"
                :items="getVacantShiftApplications"
                :per-page="pagination.perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                class="mobile_table_css"
                hover
                responsive
                @row-clicked="(item) => openPracticePopup(item)"
            >
              <template #cell(service)="data">
                <div>
                  <span class="font-weight-bolder"><strong>{{ data.item.service }}</strong></span>
                  <span>
                   <b-badge v-if="data.item.is_covid" class="ml-1 bg-light-danger" style="" variant="danger">
                   <span class="text-danger  font-weight-700"> COVID-19  </span>
                 </b-badge>
                 </span>
                  <br>
                  <span class="text-muted">{{ data.item.shift_type }}</span>
                </div>
              </template>

              <template #cell(time)="data">
                <b-row class="">
                  <span>
                    <span>
                      <feather-icon class="" icon="ClockIcon"/> {{ data.item.full_time }}
                    </span>
                    <br>
                      <span v-if="data.item.break_minutes"
                            class="text-muted">{{ data.item.break_minutes }} min break</span>
                      <span v-else class="text-muted">No break</span>
                  </span>

                </b-row>
              </template>

              <template #cell(location)="data">
                <b-row class="">

                  <span>
                     <feather-icon class="mr-1" icon="MapPinIcon"/>
                    <span class="text-primary">{{ data.item.location }}
                    </span>
                     <b-badge v-if="data.item.connected_practice" v-b-tooltip.hover.top="'Connected Practice'"
                              class="ml-1"
                              variant="light-primary">
                       <feather-icon class="" icon="LinkIcon"/>
                     </b-badge>
                  <br>
                    <span class="text-muted">{{ data.item.address }}</span>
                  </span>

                </b-row>
              </template>

              <template #cell(rate)="data">
                <b-row class="">
                  <span>
                    <feather-icon class=""
                                  icon="CreditCardIcon"/>  £
                    {{totalShiftCost(data.item.start, data.item.end, data.item.locum_rate) }}
                    <!--                  {{data.item.endTime.substring(0, 2) - data.item.startTime.substring(0, 2)) *this.form.rate}}-->               <br>
                  <span class="text-muted">£{{ data.item.locum_rate }}/hour</span>
                  </span>

                </b-row>

              </template>
              <template #cell(email)="data">
                <b-row class="">
                  <span>
                    <feather-icon class="" icon="MailIcon"/>
                     {{ data.item.created_by.first_name }} {{ data.item.created_by.last_name }}
                  <br>
                  <span class="text-muted"> {{ data.item.created_by.email }}</span>
                  </span>
                </b-row>

              </template>

              <template #cell(note)="data">
                <b-row v-if="data.item.note" class="center text-primary">
                  <feather-icon v-b-tooltip.hover="data.item.note" class="" icon="AlertCircleIcon"/>
                </b-row>

              </template>

              <template #cell(action)="data">
                <b-button
                    class="  "
                    size=""
                    variant="primary"
                    @click="applyVacantShift(data.item.id)"
                >
                  Apply
                </b-button>


                <!--              <b-button-->
                <!--                  size="23"-->
                <!--                  variant="outline-white"-->
                <!--                  class="btn-icon bg-white text-danger m-1"-->
                <!--                  v-b-tooltip.hover.top="'Reject'"-->
                <!--                  @click="rejectLeave(data.item.id)"-->
                <!--              >-->
                <!--                <feather-icon icon="XSquareIcon" size="18" variant="outline-danger"/>-->
                <!--              </b-button>-->


              </template>


            </b-table>
          </b-overlay>
        </b-col>
        <b-col v-if="noDataTable === 0" class="text-center" cols="12">
          <span>No data for preview</span>
        </b-col>
        <!-- Pagination -->

        <b-col class="d-flex align-items-center justify-content-center justify-content-sm-start" cols="12" sm="6">
          <span v-if="pagination.totalRows !==0" class="text-muted">Showing {{ pagination.from }} to {{ pagination.to }} of {{
              pagination.totalRows
            }} entries</span>
        </b-col>

        <!-- Pagination -->

        <b-col class="d-flex align-items-center justify-content-center justify-content-sm-end" cols="12" sm="6">

          <b-pagination
              v-model="currentPage"
              :per-page="pagination.perPage"
              :total-rows="pagination.totalRows"
              class="mb-0 mt-1 mt-sm-0 "
              first-number
              last-number
              next-class="next-item"
              prev-class="prev-item">

            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18"/>
            </template>

            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18"/>
            </template>

          </b-pagination>

        </b-col>
      </b-row>
    </b-card>

    <popup ref="popupModel" @refresh="$refs.table.refresh()"/>

  </div>
</template>

<script>
import {
  BAlert,
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BOverlay,
  BPagination,
  BRow,
  BSidebar,
  BTab,
  BTable,
  BTabs,
  VBTooltip
} from 'bootstrap-vue'
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import shift from '@/apis/modules/shift'
import locations from '@/apis/modules/locations'
import profile from '@/apis/modules/profile'
import MomentMixin from "@/mixins/MomentMixin";
import Popup from '@/views/shifts/popup.vue'
import Filter from '@/mixins/FilterMixin'
import Ripple from 'vue-ripple-directive'
import {mapGetters} from "vuex";

export default {
  name: 'view-invoice',
  mixins: [MomentMixin, Filter],
  components: {
    Popup,
    BButton,
    flatPickr,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    vSelect,
    BInputGroupPrepend,
    BCard,
    BTabs,
    BTab,
    ValidationProvider,
    ValidationObserver,
    BAlert,
    BForm,
    BFormCheckbox,
    BFormDatepicker,
    BFormInvalidFeedback,
    BSidebar,
    BFormTextarea,
    VBTooltip,
    BOverlay,

  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      location: undefined,
      mounted: false,
      userApplyShowVacantShiftCalendar: false,
      bank_acc: '',
      min_rate: '',
      max_rate: '',
      selected: '',
      test: '',
      role: '',
      toggle: '',
      selectedDate: '',
      filterDate: '',
      showCreateInvoice: false,
      invoiceModalLoading: false,
      filters: {
        'practice.id' : undefined
      },
      leaveList: [],
      dateFilterOptions: '',
      locationFilterOptions: [],
      documentList: [],
      leaveLoading: false,
      buttonLoading: false,
      tableLoading: false,
      noDataTable: '',
      uploadedFile: null,
      showFileUploadModal: false,
      modalLoading: false,
      currentPage: 1,
      pagination: {
        perPage: '',
        pageOptions: '',
        totalRows: '',
        from: '',
        to: ''
      },
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'date',
          label: 'Date',
        },
        {
          key: 'service',
          label: 'service',
        },
        {
          key: 'time',
          label: 'time',
          tdClass: 'timeClass'
        },

        {
          key: 'location',
          label: 'location',
        },


        {
          key: 'rate',
          label: 'rate',
        },
        {
          key: 'email',
          label: ' email',
        },
        {
          key: 'note',
          label: '',
        },
        {
          key: 'action',
          label: '',
        },
      ]
    }
  },
  methods: {
    async getLocations() {
      this.locationFilterOptions = (await locations.getLocations()).data.data
    },

    async getVacantShiftApplications() {
      this.tableLoading = true
      try {
        const Response = await shift.getApplicationListVacantShifts(this.filterQuery)
        this.noDataTable = Response.data.data.length

        const locations = Response.data.data.filter(location => location.practice.length !== 0)

        let dataArray = Response.data.data.map((x) => ({
          id: x.id,
          start: x.start,
          break_minutes: x.break_minutes,
          end: x.end,
          date: this.momentFormat(x.start, 'dddd YYYY-MM-DD'),
          balance: (this.momentFormat(x.end, 'H:i:ss').substring(0, 2) - this.momentFormat(x.start, 'H:i:ss').substring(0, 2)) * x.rate,
          service: x.service === null ? 'N/A' : x.service.name,
          shift_type: x.shift_type === null ? 'N/A' : x.shift_type.name,
          rate: x.rate === null ? 0 : x.rate,
          email: '',
          full_time: `${this.momentFormat(x.start, 'HH:mm')}` + ' - ' + `${this.momentFormat(x.end, 'HH:mm')}`,
          start_time: `${this.momentFormat(x.start, 'HH:mm')}`,
          end_time: `${this.momentFormat(x.end, 'HH:mm')}`,
          is_covid: x.is_covid_shift,
          applications: x.applications,
          created_by: x.created_by,
          locum_rate: x.locum_rate,
          note: x.note,
          address: x.practice.address,
          location: x.practice.name,
          phone: x.practice.phone_number,
          practice_name: x.practice.name,
          practice: x.practice,
          connected_practice: x.practice.locums.length > 0,
          requested_to_connect: x.practice.locum_requests.length > 0

        }))
        const paginationResponse = Response.data.meta
        this.currentPage = paginationResponse.current_page
        this.pagination.totalRows = paginationResponse.total
        this.pagination.perPage = paginationResponse.per_page
        this.pagination.from = paginationResponse.from
        this.pagination.to = paginationResponse.to

        this.tableLoading = false
        return dataArray

      } catch (error) {
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }
    },
    async getUserVacantShiftState() {
      try {
        this.userApplyShowVacantShiftCalendar = !!this.currentUser.show_vacant_shift
        console.log(this.userApplyShowVacantShiftCalendar)
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async changeVacantShiftShowState() {

      try {
        if (!this.userApplyShowVacantShiftCalendar) {
          this.$swal({
            title: 'Are you sure you want to disable show vacant?',
            text: 'Vacant will be not shown on calender',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, disable',
            cancelButtonText: 'No, go back',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1'
            },
            buttonsStyling: false
          }).then(async result => {
            if (result.value) {
              const Response = await shift.showVacuntShift();
              this.userApplyShowVacantShiftCalendar = false

            } else {
              this.userApplyShowVacantShiftCalendar = true
            }
          })
        } else {
          console.log(this.userApplyShowVacantShiftCalendar)
          const Response = await shift.showVacuntShift();
          this.userApplyShowVacantShiftCalendar = true
        }

      } catch (error) {
        console.error(error);
      }
    },

    async applyVacantShift(id) {
      try {
        const response = await shift.applyVacantshifts(id)
        this.showSuccessMessage('Applied successfully')
        this.$refs.table.refresh()
      } catch (error) {
        this.convertAndNotifyError(error)
      }
    },
    openPopup() {
      this.$refs.popupModel.openModal()
    },
    openPracticePopup(items) {
      this.$refs.popupModel.openModal(items)
    },
    filterQueryUpdate() {
      if (this.mounted) this.$refs.table.refresh()
    },
  },
  computed: {
    ...mapGetters(['currentUser']),

  },
  watch: {
    location(value) {
      if (value[0]) this.filters['practice.id'] = value[0].id
      else this.filters['practice.id'] = undefined
    }
  },
  async mounted() {
    setTimeout(() => {
      this.mounted = true
    }, 1000)
    await this.getLocations()
     await this.getUserVacantShiftState()
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
/*print style*/
@import '@core/scss/vue/libs/vue-flatpicker.scss';


</style>

<style scoped>
/* .timeClass{
  max-width: 100px;
} */
</style>
