<template>
  <div>
    <b-modal
        id="modal-center"
        v-model="showPopup"
        no-close-on-backdrop

        centered
        hide-footer
        size="lg"
    >
      <b-overlay
          :show="modalLoading"
          rounded="sm"
      >



  <b-card no-shadow class="shadow-none">
    <b-card-body no-shadow>
        <div class="m2-1">
            <div class="row">
              <img alt="" class="img-fluid" src="@/assets/images/pages/iconab.png">
            </div>
        </div>
        <div class="mt-1">
            <div class="row">
                <span> <span  class=" h3 font-weight-bolder text-dark">{{items.practice.name}}  </span>
                  <b-badge v-if="items.practice.locum_requests.length > 0 && items.practice.locum_requests[0].state === 'approval pending' && !items.connected_practice" variant="light-warning"
                  class="">
                  Pending Request
                </b-badge>
                <b-badge v-if="items.connected_practice" class="ml-1" variant="light-primary" v-b-tooltip.hover.top="'Connected Practice'">
                       <feather-icon class="" icon="LinkIcon"/>
                     </b-badge>
                </span>
            </div>
        </div>
        <div class="mt-1">
            <div class="row">
                <div class="d-flex align-items-center mr-3 mb-1"><feather-icon icon="MapPinIcon" class="mr-1"  />{{items.practice.address}}</div>
                <div class="d-flex align-items-center mr-3 mb-1"><feather-icon icon="PhoneIcon" class="mr-1"  /><span v-if="items.phone">{{items.practice.phone_number}}</span> <span v-else>No number</span></div>
                <div class="d-flex align-items-center mr-3 mb-1"><feather-icon icon="MailIcon" class="mr-1"  />{{items.practice.email}}</div>
            </div>
        </div>
        <div class="mt-1">
            <div class="row">
<!--                <b-button
                    size=""
                    variant="primary"
                    class="  "
                >
                  <feather-icon icon="MapIcon" class="mr-1"  />View Map
                </b-button>-->
              <b-button
                  v-if="!items.connected_practice && !items.requested_to_connect"
                  size=""
                  variant="primary"
                  class=""
                  @click="sendRequestToPractice(items.practice.id)"
              >
                <feather-icon icon="PlusIcon" class="mr-1"  /> Request to Be Connected
              </b-button>
            </div>
        </div>
    </b-card-body>
 </b-card >



      </b-overlay>
      </b-modal>

  </div>
</template>

<script>

import {
  BAlert,
  BAvatar, BAvatarGroup, BBadge,
  BButton, BButtonGroup,
  BCard, BCardBody,
  BCardHeader,
  BCardText, BCardTitle,
  BCol, BDropdown, BDropdownItem, BFormCheckbox, BFormGroup, BFormInput,
  BImg, BInputGroup, BInputGroupPrepend, BLink, BListGroupItem,
  BMedia, BModal, BOverlay, BPagination,
  BRow,
  BTab, BTable,
  BTabs, VBModal, VBToggle,
  VBTooltip
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import shift from "@/apis/modules/shift";


export default {

  components: {
    BTabs,
    BCardText,
    BTab,
    flatPickr,
    VBTooltip,
    BMedia,
    BImg,
    BCard,
    BCardHeader,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BLink,
    BCardBody,
    BBadge,
    VuePerfectScrollbar,
    BListGroupItem,
    BCardTitle,
    BFormGroup,
    VBToggle,
    BModal, VBModal, BAlert,
    BButtonGroup,
    BOverlay, BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BAvatarGroup,
    BFormInput,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
  },

  data(){
    return{
        items: '',
        showPopup:false,
        modalLoading:false,
    }
  },
//   props:{
//     items: Object
//   },
  mounted(){

  },
  methods:{
   openModal(data){
    this.items = data;
    this.showPopup = true
   },
    async sendRequestToPractice(id){
     try {
       this.modalLoading = true
       const response = await shift.sendConnectionRequest({practice_id:id})
       this.showSuccessMessage(`Connection request was sent to ${this.firstLetterUpperCase(this.items.practice.name)} Practice!`)
       this.$emit('refresh')
       this.modalLoading = false
       this.showPopup = false

     }catch (error){
       this.convertAndNotifyError(error)
       this.modalLoading = false
     }
    }
  },


}
</script>

<style scoped>

</style>
